<script setup lang="ts">
import { useStore } from 'vuex'
import GuideMapPopup from '~/components/common/GuideMapPopup.vue'
import iconChatBot from '~/assets/images/icon/icon-chat-bot.png'
import IconHelp from '~/assets/images/icon/icon-help.svg?component'
import IconMap from '~/assets/images/icon/icon-map.svg?component'

const store = useStore()

// Onboarding
const openOnBoardingDialog = () => {
  store.commit('onboarding/openDialog')
}

// StoreMap
const isStoreMapOpen = ref(false)
const openStoreMap = () => {
  isStoreMapOpen.value = true
}
</script>

<template>
  <v-app-bar>
    <template #prepend>
      <div class="avatar">
        <v-avatar :image="iconChatBot" size="42"></v-avatar>
      </div>
    </template>

    <v-app-bar-title class="font-weight-bold ml-1">
      CHOOSEBASE<br />SHIBUYA
    </v-app-bar-title>

    <template #append>
      <v-btn icon size="x-small" @click="openStoreMap">
        <IconMap />
        <GuideMapPopup v-model="isStoreMapOpen" />
      </v-btn>
      <v-btn icon size="x-small" @click="openOnBoardingDialog">
        <IconHelp />
      </v-btn>
      <ChatHeaderLanguageMenu />
    </template>
  </v-app-bar>
</template>
<style lang="sass" scoped>
.avatar
  padding: 3px
</style>
