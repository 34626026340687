<script setup lang="ts">
import IconChevronDown from '~/assets/images/icon/icon-chevron-down.svg?component'

const languageStore = useLanguageStore()

const currentLanguage = computed(() => languageStore.languageCode)
const supportedLanguages = computed(() => SUPPORTED_LANGUAGES)
const currentLanguageObject = computed(() =>
  supportedLanguages.value.find(
    (language) => language.code === currentLanguage.value
  )
)

const handleSelect = (index: number) => {
  languageStore.setLanguageCode(supportedLanguages.value[index].code)
}

// TODO: テキストの長さによって横のアイコンが動くのと、リストがずれるので最悪幅固定する
</script>

<template>
  <v-menu offset-y>
    <template #activator="{ props }">
      <v-btn v-bind="props" class="pl-3 pr-2">
        {{ currentLanguageObject?.displayName }}
        <IconChevronDown class="ml-1"></IconChevronDown>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, index) in supportedLanguages"
        :key="index"
        @click="handleSelect(index)"
      >
        <v-list-item-title>{{ item.displayName }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
