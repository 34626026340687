<template>
  <v-dialog
    :model-value="props.modelValue"
    content-class="dialog-content"
    width="calc(100% - 24px)"
  >
    <v-card class="rounded-lg">
      <div class="custom-padding">
        <GuideMap width="100%" height="219" />
      </div>
    </v-card>
    <v-card-actions class="justify-center">
      <v-btn class="no-bg" icon @click="closeDialog">
        <IconClose class="icon-close" width="30" height="30" />
      </v-btn>
    </v-card-actions>
  </v-dialog>
</template>

<script setup lang="ts">
import IconClose from '~/assets/images/icon/icon-close.svg?component'
import GuideMap from '~/assets/images/popup/guide-map.svg?component'

interface Props {
  modelValue: boolean
}
const props = defineProps<Props>()

const emit = defineEmits(['update:modelValue'])
const closeDialog = () => {
  emit('update:modelValue', false)
}
</script>

<style scoped>
/* svgにfillが指定されていてプロパティだと効かないのでstyleで指定 */
.icon-close :deep() path {
  fill: white !important;
}

.custom-padding {
  padding-top: 29px;
  padding-bottom: 30px;
  padding-left: 26px;
  padding-right: 26px;
}
</style>
