<script setup lang="ts">
import { useStore } from 'vuex'
import FloatingNavigation from '~/components/common/FloatingNavigation.vue'

const snackbar = useSnackbar()
provide(snackbarInjectionKey, snackbar)

const store = useStore()
const showOnboardingDialog = computed(() => {
  return store.getters['onboarding/showDialog']
})
</script>

<template>
  <v-app class="chat-layout">
    <ChatHeader />
    <v-main>
      <slot />
    </v-main>
    <v-footer app fixed color="transparent" height="98">
      <FloatingNavigation v-show="!showOnboardingDialog" />
    </v-footer>
    <OnboardingDialog />
    <BSSnackbar
      v-model="snackbar.isVisible.value"
      v-bind="snackbar.snackbarProps"
      @close="snackbar.closeSnackbar"
    />
  </v-app>
</template>

<style lang="sass">
html
  font-size: 13px

.v-row
  font-size: 0.85rem
  font-family: $en-font-family

.v-card-title
  font-size: 0.95rem
  font-family: $en-font-family

.v-card-text
  font-size: 0.85rem
  font-family: $en-font-family

.text-button
  font-size: 0.8rem !important
  font-family: $en-font-family !important
</style>

<style lang="sass" scoped>
.chat-layout
  background-color: $main-bg-color !important
</style>
